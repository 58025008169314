export const TITLES = {
  HOME: "✈️🌎 Waypoints - The smarter way to travel. ✈️🌎",
  POST: "✈️🌎 Waypoints - The smarter to travel. ✈️🌎",

  PRIVACY: "Privacy policy",
};

export const DESCRIPTIONS = {
  HOME: "Want to fly to a destination but can't find an affordable direct flight? Why not save money by finding a Waypoint. Our system can find you the cheapest connecting city to your final destination, and allows you to explore another location for less than it would have cost to deal with a boring layover. Stop and hang out in a new city and continue on your way a few days later.",
  POST: "Want to fly to a destination but can't find an affordable direct flight? Why not save money by finding a Waypoint. Our system can find you the cheapest connecting city to your final destination, and allows you to explore another location for less than it would have cost to deal with a boring layover. Stop and hang out in a new city and continue on your way a few days later.",

  PRIVACY:
    "This Privacy Policy describes our policies and procedures on the collection, use and disclosure of Your information when you use the Service and tells You about Your privacy rights and how the law protects you.",
};
